import React, { Component } from "react"
import { Link } from "gatsby"
import Header from "../components/header.js"

export default class HamburgerMenu extends Component {
  constructor(props) {
    super(props)
    this.smoothScrollToElement = this.props.smoothScroll
  }

  render() {
    return (
      <div id="hamburger-menu" className="invisible">
        <nav id="hamburger-nav">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="#about" onClick={e => this.smoothScrollToElement(e)}>
                About
              </Link>
            </li>
            <li>
              <Link to="#contact" onClick={e => this.smoothScrollToElement(e)}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}
