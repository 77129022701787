import React from "react"

// Asset imports
import artist1 from "../images/artist1.jpg"
import artist2 from "../images/artist2.jpg"

const About = () => (
  <section id="about">
    <h2>Who We Are</h2>

    <div className="artists-wrapper">
      <div className="artist-container">
        <p>
          My name is John and I’ve been a professional tattoo artist for 15
          years. I love what I do and look forward to seeing every customer’s
          smile when they see the beautiful work I leave them with.
        </p>
        <img src={artist1} alt="John" id="artist-one" />
      </div>
      <div className="artist-container">
        <img src={artist2} alt="Amber" id="artist-two" />
        <p id="second-artist-description">
          My name is Amber and I’ve created art my whole life. From the sketch
          pad, to of course skin. I put my heart and soul into every tattoo and
          I know you’ll never want another artist after me.
        </p>
      </div>
    </div>

    <p id="about-message">
      No matter who you choose to do your work, we know you’ll walk out of the
      shop happy and confident you found your life long tattoo artist.
    </p>
  </section>
)

export default About
