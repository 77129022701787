import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import flamingo from "../images/flamingo.svg"
import hamburgerMenu from "../images/menu.svg"

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.toggleHamburgerMenu = this.toggleHamburgerMenu.bind(this)
    this.applyFixedHeader = this.applyFixedHeader.bind(this)
    this.smoothScrollToElement = this.props.smoothScroll.bind(this)
  }

  render() {
    return (
      <header>
        <img
          src={hamburgerMenu}
          alt="Menu Icon"
          id="hamburger-icon"
          onClick={this.toggleHamburgerMenu}
        />

        <div className="top-bar">
          <h1>
            <Link
              to="/"
              onClick={() => {
                if (
                  !document
                    .getElementById("hamburger-menu")
                    .classList.contains("invisible")
                ) {
                  this.toggleHamburgerMenu()
                }
              }}
            >
              {this.props.siteTitle}
              <img src={flamingo} alt="" />
            </Link>
          </h1>
          <nav id="nav">
            <ul>
              <li>
                <Link to="/" onClick={e => this.smoothScrollToElement(e)}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="#about" onClick={e => this.smoothScrollToElement(e)}>
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="#contact"
                  onClick={e => this.smoothScrollToElement(e)}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="cta-container">
          <h2>
            Your body is your personal canvas, let us help you fill it out.
          </h2>
          <div className="cta">
            <h3>Schedule today!</h3>
            <button
              onClick={() =>
                this.smoothScrollToElement(document.getElementById("contact"))
              }
            >
              Click Here
            </button>
          </div>
        </div>
      </header>
    )
  }

  componentDidMount() {
    window.addEventListener("scroll", this.applyFixedHeader)
  }

  toggleHamburgerMenu() {
    const hamburgerMenu = document.getElementById("hamburger-menu")

    if (hamburgerMenu.classList.contains("invisible")) {
      hamburgerMenu.classList.remove("invisible")

      document.querySelector("body").style.overflow = "hidden"

      // Timeout needed to ensure clicking links works correctly
      setTimeout(
        () => hamburgerMenu.addEventListener("click", this.toggleHamburgerMenu),
        0
      )
    } else {
      hamburgerMenu.classList.add("invisible")

      document.querySelector("body").style.overflow = "visible"

      hamburgerMenu.removeEventListener("click", this.toggleHamburgerMenu)
    }
  }

  applyFixedHeader() {
    const header = document.querySelector("header")
    const topBar = document.querySelector(".top-bar")
    const hamburgerIcon = document.getElementById("hamburger-icon")

    const headerOffset = header.offsetTop

    if (window.pageYOffset > headerOffset) {
      topBar.classList.add("sticky")
      hamburgerIcon.classList.add("sticky-hamburger")
    } else {
      topBar.classList.remove("sticky")
      hamburgerIcon.classList.remove("sticky-hamburger")
    }

    if (window.pageYOffset > headerOffset) {
      topBar.classList.add("dark-bg")
    } else {
      topBar.classList.remove("dark-bg")
    }
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  location: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  location: "/",
}
