import React, { Component } from "react"
import Header from "../components/header"
import HamburgerMenu from "../components/hamburgerMenu"
import About from "../components/about"
import Contact from "../components/contact"
import SEO from "../components/seo"
import OpeningStatement from "../components/openingStatement"

export default class IndexPage extends Component {
  render() {
    return (
      <>
        <HamburgerMenu smoothScroll={this.smoothScrollToElement} />
        <SEO title="Home" />
        <Header
          siteTitle="The Tattoo Shop"
          smoothScroll={this.smoothScrollToElement}
        />
        <OpeningStatement />
        <About />
        <Contact />
      </>
    )
  }

  smoothScrollToElement(element) {
    // Check if a direct element is passed in or if an event is passed in from an <a> link.
    // Prevent <a> link's default behavior and set element to the event.target
    if (element.target) {
      element.preventDefault()
      element = element.target
    }

    const elementHref = element.getAttribute("href")

    // Grabs correct element to scroll from passed element's href
    if (elementHref && elementHref !== "/") {
      element = document.querySelector(elementHref.slice(1))
    } else if (element.innerText === "Home") {
      element = document.querySelector("header")
    }

    // Offset needed due to sticky header, othewise scroll will go to wrong position
    const yOffset = -104
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

    window.scrollTo({ top: y, behavior: "smooth" })
  }
}
