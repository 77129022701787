import React from "react"

// Asset imports
import tattoo1 from "../images/tattoo1.jpg"
import tattoo2 from "../images/tattoo2.jpg"
import tattoo3 from "../images/tattoo3.jpg"
import tattoo4 from "../images/tattoo4.jpg"

const OpeningStatement = () => (
  <section className="opening-statement">
    <h2>
      Bring Your Body to <span style={{ color: "white" }}>LIFE</span>
    </h2>

    <div className="opening-images">
      <img src={tattoo1} alt="tattoo" />
      <img src={tattoo2} alt="tattoo" />
      <img src={tattoo3} alt="tattoo" />
      <img src={tattoo4} alt="tattoo" />
    </div>

    <p>
      At The Tattoo Shop, we strive to give you the best possible artwork at a
      rate you can afford. We never cut corners on quality so you can rest
      assured your tattoo will be timeless and adored by all.
    </p>
  </section>
)

export default OpeningStatement
