import React from "react"

// Asset imports
import mapIcon from "../images/map.svg"
import phoneIcon from "../images/phone.svg"
import emailIcon from "../images/mail.svg"

const Contact = () => (
  <section id="contact">
    <div className="contact-info">
      <div className="contact-info-inner">
        <div className="contact-container">
          <p className="contact-type">
            <img src={mapIcon} alt="Map Icon" />
            Address
          </p>
          <a
            href="https://www.google.com/maps/place/521+S+Main+St,+Clintonville,+WI+54929/@44.5974746,-88.7674354,15z/data=!4m5!3m4!1s0x8802273ef6848ed1:0xf4f26953ef71f5b8!8m2!3d44.6022129!4d-88.7654324"
            target="_blank"
          >
            <p className="contact-description">
              521 S. Main St, Clintonville, WI 54929
            </p>
          </a>
        </div>

        <div className="contact-container">
          <p className="contact-type">
            <img src={phoneIcon} alt="Phone Icon" />
            Reach Out At
          </p>
          <a href="tel:+1-555-555-5555">
            <p className="contact-description">(555) 555-5555</p>
          </a>
        </div>

        <div className="contact-container">
          <p className="contact-type">
            <img src={emailIcon} alt="Email Icon" />
            Prefer Email?
          </p>
          <a href="mailto:john@thetattooshop.com">
            <p className="contact-description">john@thetattooshop.com</p>
          </a>
        </div>
      </div>
    </div>

    <div className="contact-form">
      <h2>Send Us A Message</h2>

      <form onSubmit={e => e.preventDefault()} id="form">
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" />

        <label htmlFor="email">Email</label>
        <input type="text" name="email" id="email" />

        <label htmlFor="phone-number">Phone Number</label>
        <input type="text" name="phone-number" id="phone-number" />

        <label htmlFor="message" id="message-label">
          Message
        </label>
        <textarea name="message" form="form" id="message"></textarea>

        <button>Send Message</button>
      </form>
    </div>
  </section>
)

export default Contact
